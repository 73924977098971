// mobile menu toggle
$(document).ready(function(){
  var $burger = $(".hamburger");
  var $mobileMenu = $(".main-navigation-mobile-wrapper");
  var $mobileSolutionsLink = $(".js-mobile-solutions-link");
  var $mobileSolutionsMenu = $(".js-mobile-solutions");

  $burger.on("click", function(){
      $mobileMenu.slideToggle();
      $(this).toggleClass("is-active");
      $("body").toggleClass("iphone-fix");
  });

  $mobileSolutionsLink.on("click", function(e){
    e.preventDefault();
    $mobileSolutionsMenu.slideToggle();
  });
})
