// sticky navigation
$(document).ready(function(){

  $(window).scroll(function() {
    var scroll = $(window).scrollTop();

    if (scroll >= 10) {
        $("body").addClass("scrolled");
    } else {
       $("body").removeClass("scrolled");
    }
  });
})
